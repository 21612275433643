import axios from 'axios';
import 'normalize.css';
import React, { useEffect, useState } from 'react';
import './App.css';
import Map from './Components/Map';
import db from './firebase';
import calendar from './images/calendar.svg';
import creditCard from './images/credit-card.svg';
import man from './images/man.svg';
import pigBank from './images/piggy-bank.svg';
import scheduleNow from './images/schedule-now.svg';
import twomaidslogo from './images/twomaidslogo.svg';

function App() {
  const [onlineUserCount, setOnlineUserCount] = useState(0);
  const [totalLeadCount, setTotalLeadCount] = useState(0);
  const [totalRevenue, setTotalRevenue] = useState(0);
  const [averageTransaction, setAverageTransaction] = useState(0);
  const [totalCleanings, setTotalCleanings] = useState(0);
  const [scheduleNowCount, setScheduleNowCount] = useState(0);
  const [scheduleNowRevenue, setScheduleNowRevenue] = useState(0);
  const [totalOpenFranchisees, setTotalOpenFranchisees] = useState(0);
  const [upcomingLocations, setTotalUpcomingLocations] = useState(0);
  const [monthlyTips, setMonthlyTips] = useState(0);
  const [monthlyServiceFee, setMonthlyServiceFee] = useState(0);
  const [toggleKpiValue, setToggleKpiValue] = useState(false);
  const [franchises, setFranchises] = useState([]);

  const [firstRender, setFirstRender] = useState(false);
  const currentYear = new Date().getFullYear();

  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  const updateRevenue = () => {
    if (!firstRender) return;
    gataware
      .post('/total-monthly-revenue/')
      .then((result) => {
        const revenue = result.data.total_monthly_estimated_revenue;
        const appointments = result.data.total_monthly_estimated_cleans_count;
        const avgTranscation = revenue / appointments;
        const monthlyScheduleNowCount = result.data.monthly_schedule_now_count;
        const monthlyScheduleNowRevenue =
          result.data.monthly_schedule_now_revenue;
        const totalLocations = result.data.total_open_franchisees;
        const upcoming_locations = result.data.upcoming_locations;
        const monthly_tips = result.data.monthly_tips;
        const monthly_service_fee = result.data.monthly_service_fee;

        setTotalOpenFranchisees(totalLocations);
        setTotalRevenue(revenue);
        setAverageTransaction(avgTranscation);
        setTotalCleanings(appointments);
        setScheduleNowCount(monthlyScheduleNowCount);
        setScheduleNowRevenue(monthlyScheduleNowRevenue);
        setTotalUpcomingLocations(upcoming_locations);
        setMonthlyTips(
          Number(monthly_tips.replace('.', '').slice(0, -2)).toLocaleString(
            'en'
          )
        );
        setMonthlyServiceFee(Number(monthly_service_fee).toLocaleString('en'));
      })
      .catch((e) => {
        console.log(e);
        setTotalRevenue(0);
      });
  };

  useEffect(() => {
    setTimeout(() => {
      window.location.reload();
    }, 14400000);
  }, []);

  const setTotalUsers = (users) => {
    setOnlineUserCount(users);
  };
  let leadsRef = db.ref('/leads/');

  const config = {
    baseURL: 'https://gataware.com/rest-api',
    // baseURL: 'http://localhost:8000/rest-api',

    headers: {
      'X-Api-Key': process.env.REACT_APP_GATAWARE_API,
      'Content-Type': 'application/json',
    },

    responseType: 'json', // default

    timeout: 8000, // default is `0` (no timeout)

    maxRedirects: 5, // default

    validateStatus(status) {
      return status >= 200 && status < 500; // default
    },
  };

  const gataware = axios.create(config);

  useEffect(() => {
    leadsRef.on('value', (snapshot) => {
      console.log(snapshot.numChildren());
      setTotalLeadCount(snapshot.numChildren());
    });
    // setTotalUsers(onlineUsers.length);
  }, []);
  // useEffect(() => {
  //   gataware
  //     .post('/get-locations/')
  //     .then((res) => {
  //       console.log('res', res);
  //       setFranchises(res.data.data);
  //     })
  //     .catch(() => {});
  // }, []);

  useEffect(() => {
    gataware
      .post('/total-monthly-revenue/')
      .then((result) => {
        console.log(result);
        const revenue = result.data.total_monthly_estimated_revenue;
        const appointments = result.data.total_monthly_estimated_cleans_count;
        const avgTranscation = revenue / appointments;
        const monthlyScheduleNowCount = result.data.monthly_schedule_now_count;
        const monthlyScheduleNowRevenue =
          result.data.monthly_schedule_now_revenue;
        const totalLocations = result.data.total_open_franchisees;
        const upcoming_locations = result.data.upcoming_locations;
        const monthly_tips = result.data.monthly_tips;
        const monthly_service_fee = result.data.monthly_service_fee;

        setTotalOpenFranchisees(totalLocations);
        setTotalRevenue(revenue);
        setAverageTransaction(avgTranscation);
        setTotalCleanings(appointments);
        setScheduleNowCount(monthlyScheduleNowCount);
        setScheduleNowRevenue(monthlyScheduleNowRevenue);
        setTotalUpcomingLocations(upcoming_locations);
        setMonthlyTips(
          Number(monthly_tips.replace('.', '').slice(0, -2)).toLocaleString(
            'en'
          )
        );
        setMonthlyServiceFee(Number(monthly_service_fee).toLocaleString('en'));

        setTimeout(() => {
          setFirstRender(true);
        }, 1000);
      })
      .catch((e) => {
        console.log(e);
        setTotalRevenue(0);
      });
  }, []);

  useEffect(() => {
    document.title = `KPI Dashboard (${onlineUserCount})`;
  }, [onlineUserCount]);

  const toggleKpiValues = () => {
    setToggleKpiValue(!toggleKpiValue);
  };

  useEffect(() => {
    const intervalID = setInterval(toggleKpiValues, 30000);
    return () => clearInterval(intervalID);
  }, [toggleKpiValues]);

  return (
    <div className='App'>
      <canvas></canvas>
      <img
        src="https://res.cloudinary.com/twomaidsengi/image/upload/v1670257150/hubsite-reskin/two-maids-logo-white.png"
        alt='two-maids-logo'
        className='two-maids-logo'
      />{' '}
      <div className='total-monthly-revenue-text'>
        <span>
          {monthNames[new Date().getMonth()]} {new Date().getFullYear()}
        </span>

        <span className='total-monthly-revenue'>
          <span>
            {' '}
            {totalRevenue
              ? totalRevenue[0] +
                '.' +
                totalRevenue[1] +
                totalRevenue[2] +
                totalRevenue[3]
              : '0'}
            <span
              className='total-monthly-revenue-mm'
              style={{ fontSize: '34px' }}
            >
              MM
            </span>
          </span>
        </span>

        <span className='average-transaction'>
          Average Transaction : ${Math.ceil(averageTransaction)}
        </span>

        <span className='total-cleanings'>
          Total Cleanings : {totalCleanings.toLocaleString('en')}
        </span>
      </div>
      <div className='monthly-kpis'>
        <span className='kpi-group'>
          {' '}
          <span className='total-monthly-schedule-now-revenue'>
            Schedule Now :
          </span>{' '}
          <span className='result'>
            {toggleKpiValue
              ? ` $${Number(scheduleNowRevenue).toLocaleString('en')}`
              : ` ${scheduleNowCount}`}
          </span>
        </span>
        {/* 
        <span className='total-monthly-schedule-now-count'>
          Schedule Now Count : {scheduleNowCount}
        </span> */}
        <span className='kpi-group'>
          {' '}
          <span className='online-conversion-rate'>
            {toggleKpiValue ? 'Online Conversions : ' : 'Total Leads : '}
          </span>
          <span className='result'>
            {toggleKpiValue
              ? `${((scheduleNowCount / totalLeadCount) * 100).toFixed(2)}%`
              : `${totalLeadCount.toLocaleString('en')}`}
          </span>
        </span>
        <span className='kpi-group'>
          {' '}
          <span className='online-conversion-rate'>Monthly Tips:</span>
          <span className='result'>${monthlyTips.toLocaleString('en')}</span>
        </span>
        <span className='kpi-group'>
          {' '}
          <span className='online-conversion-rate'>Service Fee:</span>
          <span className='result'>${monthlyServiceFee}</span>
        </span>
        <hr className='kpi-divider' />

        <span className='total-open-franchise'>
          Total Open Franchises :
          <span className='result'> {totalOpenFranchisees}</span>
        </span>
        <span className='upcoming-locations'>
          Upcoming Locations :
          <span className='result'> {upcomingLocations}</span>
        </span>
        <hr className='kpi-divider' />

        <span className='total-online-user-text'>
          Online Leads : <span className='result'> {onlineUserCount}</span>
        </span>
      </div>
      <Map
        setTotalUsers={setTotalUsers}
        updateRevenue={updateRevenue}
        franchises={franchises}
      />
      <div className='legend'>
        <div className='legend-item'>
          <span>Price Shopping</span>
          <hr />
          <img src={man} alt='' />
        </div>
        <div className='legend-item'>
          <span>Selecting Date</span>
          <hr />
          <img src={calendar} alt='' />
        </div>
        <div className='legend-item'>
          <span>Entering C. Card</span>
          <hr />
          <img src={creditCard} alt='' />
        </div>
        <div className='legend-item'>
          <span>Schedule Now</span>
          <hr />
          <img src={pigBank} alt='' />
        </div>
      </div>
      <div style={{ width: '100%' }}>
        <footer>
          <p>{currentYear} © Gataware Software Suite.</p>
          <p> Two Maids Franchising, LLC. </p>
          <p>All Rights Reserved. </p>
          <p> Made with &#10084; in Birmingham, Alabama.</p>
        </footer>
      </div>
    </div>
  );
}

export default App;
