import { motion } from 'framer-motion';
import React from 'react';
import { Marker } from 'react-simple-maps';

import FranchiseImage from '../images/franchise.svg';

function Franchise({ franchise }) {
  const imageVariants = {
    stepOne: { scale: 0 },
  };

  return (
    <>
      {franchise.longitude < 0 ? (
        <Marker
          coordinates={[
            Number(franchise?.longitude) ?? 0,
            Number(franchise?.latitude) ?? 0,
          ]}
        >
          <motion.g
            initial={{ scale: 0, y: 0 }}
            animate={{ rotate: 0, scale: 1, y: 0 }}
            transition={{
              type: 'spring',
              stiffness: 260,
              damping: 20,
            }}
          >
            <motion.image
              // initial={{ scale: 1 }}
              // variants={imageVariants}
              // animate={'stepOne'}
              // transition={{
              //   type: 'spring',
              //   stiffness: 260,
              //   damping: 20,
              // }}
              width='3'
              height='3'
              x='0'
              y='0'
              href={FranchiseImage}
              className={'test'}
              onClick={() => {
                alert(franchise.city);
              }}
            />
          </motion.g>
        </Marker>
      ) : (
        <></>
      )}
    </>
  );
}

export default React.memo(Franchise);
