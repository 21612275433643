import React, { useEffect, useState, useRef } from 'react';
import { motion } from 'framer-motion';
import man from '../images/man.svg';
import calendar from '../images/calendar.svg';
import creditCard from '../images/credit-card.svg';
import pigBank from '../images/piggy-bank.svg';
import scheduleNow from '../images/schedule-now.svg';
import jeffCard from '../images/jeff-card.svg';
import paulCard from '../images/paul-card.svg';
import jeffCalendar from '../images/jeff-calendar.svg';
import paulCalendar from '../images/paul-calendar.svg';
import stepOne from '../images/step-one.svg';
import stepTwo from '../images/step-two.svg';
import stepThree from '../images/step-three.svg';
import { Marker } from 'react-simple-maps';
import db from '../firebase';
import userPop from '../sounds/user-enter2.mp3';
import coinDrop from '../sounds/coin-drop.mp3';
import punchSound from '../sounds/punch-sound.mp3';
import Coins from './Coins';
import jeremyCard from '../images/jeremy-card.svg';
import jeremyCalendar from '../images/jeremy-calendar.svg';

function User({ userID, updateRevenue }) {
  const audio = new Audio(punchSound);
  const scheduleAudio = new Audio(coinDrop);
  const [currentUser, setCurrentUser] = useState({});
  const [typing, setTyping] = useState(false);
  const dbRef = db.ref(`leads/${userID}`);
  const onlineRef = db.ref(`online/${userID}`);

  const cardImages = [jeremyCard, paulCard, jeffCard];
  const calendarImages = [jeffCalendar, paulCalendar, jeremyCalendar];

  function getRandomImage(list) {
    return list[Math.floor(Math.random() * list.length)];
  }

  useEffect(() => {
    dbRef.get().then((snapshot) => {
      if (snapshot.val() === null) return;
      if (snapshot.val()?.lng > 0) return;
      setCurrentUser(snapshot.val());
    });
  }, []);

  useEffect(() => {
    setTimeout(() => {
      onlineRef.remove();
    }, 600000);
  }, []);

  useEffect(() => {
    dbRef.on('value', (snapshot) => {
      if (snapshot.val() === null) return;
      if (snapshot.val()?.lng > 0) return;
      setCurrentUser(snapshot.val());
    });
    onlineRef.on('value', (snapshot) => {
      if (snapshot.val() === null) return;
      if (snapshot.val()?.typing === true) {
        setTyping(true);
      } else {
        setTimeout(() => {
          setTyping(false);
        }, 60000);
      }
    });
  }, []);

  useEffect(() => {
    // audio.play();
    // setTimeout(() => {
    //   audio.pause();
    // }, 1000);
  }, []);

  useEffect(() => {
    if (currentUser?.step === 7) {
      updateRevenue();

      // Add Coins
      Coins('body');

      scheduleAudio.play();
      setTimeout(() => {
        scheduleAudio.pause();
      }, 10000);

      // Remove Coins
      setTimeout(() => {
        Coins(null);
      }, 10000);
    }
  }, [currentUser]);

  const variants = {
    rotate: {
      scale: 1,
      fill: ['#fd89bb91', 'rgba(0,0,0,0)', '#fd89bb91'],
      transition: {
        ease: 'linear',
        duration: 1.3,
        repeat: Infinity,
        repeatType: 'reverse',
      },
    },
    stop: { scale: 0 },
  };

  const imageVariants = {
    stepOne: { scale: 1 },
    stepTwo: {
      scale: 1.1,
    },
    stepThree: {
      scale: 1.2,
    },
    stepFourFive: {
      scale: 1.5,
    },
    stepSix: {
      scale: 1.8,
    },
    stepSeven: {
      scale: 2,
    },
  };

  return (
    <>
      {currentUser?.lat && (
        <Marker coordinates={[currentUser?.lng, currentUser?.lat]}>
          <motion.g
            initial={{ scale: 0, y: -35 }}
            animate={{ rotate: 0, scale: 1, y: 0 }}
            transition={{
              type: 'spring',
              stiffness: 260,
              damping: 20,
            }}
          >
            <motion.circle
              cx='-10'
              cy='-10'
              r='15'
              variants={variants}
              animate={typing ? 'rotate' : 'stop'}
            />{' '}
            <motion.image
              initial={{ scale: 1 }}
              variants={imageVariants}
              animate={
                currentUser.step === 1
                  ? 'stepOne'
                  : currentUser.step === 2
                  ? 'stepTwo'
                  : currentUser.step === 3
                  ? 'stepThree'
                  : currentUser.step === 4 || currentUser.step === 5
                  ? 'stepFourFive'
                  : currentUser.step === 6
                  ? 'stepSix'
                  : currentUser.step === 7
                  ? 'stepSeven'
                  : ''
              }
              transition={{
                type: 'spring',
                stiffness: 260,
                damping: 20,
              }}
              width='20'
              height='20'
              x='-20'
              y='-20'
              href={
                currentUser.step === 1
                  ? stepOne
                  : currentUser.step === 2
                  ? stepTwo
                  : currentUser.step === 3
                  ? stepThree
                  : currentUser.step === 4 || currentUser.step === 5
                  ? getRandomImage(calendarImages)
                  : currentUser.step === 6
                  ? getRandomImage(cardImages)
                  : currentUser.step === 7
                  ? scheduleNow
                  : ''
              }
              className={'test'}
              onClick={() => {
                alert(currentUser.firstName);
              }}
            />
          </motion.g>
        </Marker>
      )}
    </>
  );
}

export default React.memo(User);
