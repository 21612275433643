import React, { useEffect, useRef, useState } from 'react';
import { ComposableMap, Geographies, Geography } from 'react-simple-maps';
import db from '../firebase';
import Franchise from './Franchise';
import './Map.css';
import User from './User';

const geoUrl = 'https://cdn.jsdelivr.net/npm/us-atlas@3/states-10m.json';

const Map = ({ updateRevenue, setTotalUsers, franchises }) => {
  let onlineUsersRef = db.ref('/online/');
  const [onlineUsers, setOnlineUsers] = useState([]);

  // add online users -> used to fetch usersinformation
  useEffect(() => {
    onlineUsersRef.on('value', (snapshot) => {
      const currentUsersArray = [];
      const data = snapshot.val();
      for (let user in data) {
        currentUsersArray.push(user);
      }
      setOnlineUsers(currentUsersArray);
      setTotalUsers(currentUsersArray.length);
    });
  }, []);

  return (
    <ComposableMap projection='geoAlbersUsa'>
      <Geographies geography={geoUrl}>
        {({ geographies }) => (
          <>
            {geographies.map((geo) => (
              <Geography
                key={geo.rsmKey}
                stroke='#ffffff61'
                geography={geo}
                fill='#f8bbd0'
              />
            ))}
          </>
        )}
      </Geographies>
      {onlineUsers.map((userID) => {
        return (
          <User userID={userID} key={userID} updateRevenue={updateRevenue} />
        );
      })}
      {/* {franchises.map((franchise) => (
        <Franchise franchise={franchise} key={franchise.slug}/>
      ))} */}
    </ComposableMap>
  );
};

export default Map;
